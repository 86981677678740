import axios from "axios";
import i18next from "i18next";
const axiosConfig = (dataUrl, params = {}, method) => {
    const myAxiosRequest = axios.create({
        headers: {
            Authorization: `JWT ${JSON.parse(localStorage.token || '""')}`,
            "Accept-Language": i18next.language,
        },
    });
    myAxiosRequest.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!error.response) {
                error.message = i18next.t("errors:err_unknown");
            } else if (error?.response?.status === 500) {
                error.message = i18next.t("errors:err_500");
            } else if (error?.response?.status === 404) {
                error.message = i18next.t("errors:err_404");
            } else if (error?.response?.status === 403) {
                error.message = i18next.t("errors:err_403");
            } else if (error?.response?.status === 400) {
                let errorMessages = "";
                for (const [key, value] of Object.entries(
                    error?.response?.data
                )) {
                    errorMessages += `Error ${key}: ${value}\n`;
                }
                error.message = `${i18next.t(
                    "errors:err_400"
                )}\n${errorMessages}`;
            } else if (error?.response?.status === 405) {
                let errorMessages = "";
                for (const [key, value] of Object.entries(
                    error?.response?.data
                )) {
                    errorMessages += `Error ${key}: ${value}\n`;
                }
                error.message = `${i18next.t(
                    "errors:err_405"
                )}\n${errorMessages}`;
            } else if (error?.response?.status === 401) {
                let errorMessages = "";
                for (const [key, value] of Object.entries(
                    error?.response?.data
                )) {
                    errorMessages += `Error ${key}: ${value}\n`;
                }
                error.message = `${i18next.t(
                    "errors:err_401"
                )}\n${errorMessages}`;
            } else {
                error.message = i18next.t("errors:err_unknown");
            }
            error.message = `${error.message} ${i18next.t(
                "suggestions:contactAdmin"
            )}`;
            return Promise.reject(error);
        }
    );
    return myAxiosRequest[method](dataUrl, params);
};
export default {
    get: (dataUrl, params) => axiosConfig(dataUrl, params, "get"),
    post: (dataUrl, params) => axiosConfig(dataUrl, params, "post"),
    patch: (dataUrl, params) => axiosConfig(dataUrl, params, "patch"),
    delete: (dataUrl, params) => axiosConfig(dataUrl, params, "delete"),
};
