import capitalize from "capitalize";
/**
 * Handle Initialization function to set all resources to localStorage
 * @param {array} employees  all employees info
 * @param {array} employees_who_have_appointments  employees who have appointments info
 * @param {array} clinics  all clinics info
 * @param {array} rooms  all rooms info
 * @param {array} appointment_assignable_employees  appointment assignable employees info
 * @param {array} appointment_assignable_employees_all  all appointment assignable employees info
 * @param {array} provinces  all provinces info
 * @param {object} cities  all cities info
 * @param {array} patient_status  all patient status info
 * @param {array} relationship_to_patient  all relationship to patient info
 * @param {array} studies  all studies info
 *
 * @returns {void}  void  set all resources to localStorage
 */
const handleInitialization = (
    employees,
    employees_who_have_appointments,
    clinics,
    rooms,
    appointment_assignable_employees,
    appointment_assignable_employees_all,
    provinces,
    cities,
    patient_status,
    relationship_to_patient,
    studies
) => {
    handle_employees(employees);
    handle_employees_who_have_appointments(employees_who_have_appointments);
    handle_clinics(clinics);
    handle_patients();
    handle_appointments();
    handle_rooms(rooms);
    handle_appointment_assignable_employees(
        appointment_assignable_employees,
        appointment_assignable_employees_all
    );
    handle_phone_pre_evaluation();
    handle_initial_evaluation();
    handle_caregiver_interview();
    handle_provinces(provinces);
    handle_cities(cities);
    handlePatientStatusChoices(patient_status);
    handleRelationshipToPatientChoices(relationship_to_patient);
    handleStudies(studies);
};
export const handle_employees = (employees) => {
    const activeEmployees = employees.filter((employee) => employee.active);
    localStorage.activeEmployees = JSON.stringify(activeEmployees);
    localStorage.employees = JSON.stringify(employees);
    localStorage.voidEmployee = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        first_name: "Void",
        last_name: "Employee",
        email: "void@void.com",
        active: true,
        position: "Void Position",
        title: "Void Title",
    });
};
export const handle_employees_who_have_appointments = (
    employees_who_have_appointments
) => {
    let appointment_resource_employees_source =
        employees_who_have_appointments.map((employee) => ({
            id: employee.uuid,
            text: `${
                employee?.title ? employee.title + " " : ""
            }${capitalize.words(employee?.first_name)} ${capitalize.words(
                employee?.last_name
            )}`,
            filtered: true,
        }));
    appointment_resource_employees_source = [
        {
            id: "00000000-0000-0000-0000-000000000000",
            text: "Void Employee",
            filtered: true,
        },
        ...appointment_resource_employees_source,
    ];
    localStorage.employees_who_have_appointments = JSON.stringify(
        employees_who_have_appointments
    );
    localStorage.appointment_resource_employees = JSON.stringify(
        JSON.parse(
            localStorage?.appointment_resource_employees ||
                JSON.stringify(appointment_resource_employees_source)
        )
    );
};
export const handle_clinics = (clinics) => {
    localStorage.clinics = JSON.stringify(clinics);
    localStorage.clinicsOptions = JSON.stringify(
        clinics.map((clinic) => [clinic.uuid, capitalize.words(clinic.name)])
    );
};
export const handle_patients = () => {
    localStorage.voidPatient = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        first_name: "Void",
        last_name: "Patient",
        phones: [],
        gender: "",
        city: "",
        province: "",
        email: "void@void.com",
    });
    localStorage.voidCreateNewPatient = JSON.stringify({
        active: "",
        active_memory_concerns: "",
        active_memory_concerns_onset_date: "",
        birth_date: "",
        chart_number: "",
        city: "",
        deceased_date: "",
        education_level: "",
        email: "",
        ethnicity: "",
        race: "",
        race_other: "",
        extraction_of_anonymous_information: "",
        family_doctor_clinic_address: "",
        family_doctor_email: "",
        family_doctor_name: "",
        family_doctor_phone_number: "",
        family_ties: "",
        first_name: "",
        gap: "",
        gender: "",
        health_card_expiration_date: "",
        health_care_number: "",
        how_heard_about_us: "",
        last_name: "",
        licence_plate_number: "",
        name_of_resident_professional: "",
        name_of_treating_professional: "",
        non_potential_studies: "",
        number_of_child: "",
        occupation: "",
        person_to_contact_for_appointment: "",
        pharmacy_address: "",
        pharmacy_fax_number: "",
        pharmacy_name: "",
        pharmacy_phone_number: "",
        phones: [],
        postal_code: "",
        potential: "",
        potential_date: "",
        preferred_communication_method: "",
        preferred_language: "",
        preferred_name: "",
        primary_clinic: "",
        primary_contact_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_relationship: "",
        primary_contact_phone_numbers: [],
        province: "",
        referred_by: "",
        secondary_contact_email: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relationship: "",
        secondary_contact_phone_numbers: [],
        sex: "",
        street_address: "",
        study_code: "",
        suppression_date: "",
        years_of_education: "",
    });
    localStorage.voidOnePatientInfo = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        active: "",
        active_memory_concerns: "",
        active_memory_concerns_onset_date: "",
        age: "",
        alerts: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                content_type: "patient",
                employee: JSON.parse(localStorage.voidEmployee),
                obj_reference: "00000000-0000-0000-0000-000000000000",
                value: "",
                active: true,
            },
        ],
        allergies: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                name: "",
            },
        ],
        appointments: [],
        birth_date: "",
        chart_number: "",
        city: "",
        creation_date: "",
        deceased_date: "",
        dossier_cmo: "",
        education_level: "",
        email: "",
        ethnicity: "",
        race: "",
        race_other: "",
        extraction_of_anonymous_information: "",
        family_doctor_clinic_address: "",
        family_doctor_email: "",
        family_doctor_name: "",
        family_doctor_phone_number: "",
        family_ties: "",
        file_sync_info: "",
        files: [],
        files_sync: "",
        first_name: "",
        gap: "",
        gender: "",
        health_card_expiration_date: "",
        health_care_number: "",
        how_heard_about_us: "",
        intake_forms: [],
        last_name: "",
        latitude: "",
        licence_plate_number: "",
        longitude: "",
        medesync_profile_code: "",
        medical_problems: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                active: false,
                employee: JSON.parse(localStorage.voidEmployee),
                end_date: "",
                problem: "",
                self_reported: false,
                start_date: "",
            },
        ],
        medications: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                start_date: "",
                end_date: "",
                administration_route: "",
                course_completed: false,
                dosage: "",
                dosage_unit: "",
                dose_frequency: "",
                form: "",
                inactive: false,
                inactive_reason: "",
                indication: "",
                medication: {
                    uuid: "00000000-0000-0000-0000-000000000000",
                    brand: "",
                    din: "",
                    forms: [""],
                    name_en: "",
                    name_fr: "",
                },
            },
        ],
        mmse: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                date: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                score_numerator: "",
            },
        ],
        moca: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                date: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                score_numerator: "",
                score_denominator: "",
            },
        ],
        name_of_resident_professional: "",
        name_of_treating_professional: "",
        non_potential_studies: "",
        notes: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                content_type: "patient",
                employee: JSON.parse(localStorage.voidEmployee),
                obj_reference: "00000000-0000-0000-0000-000000000000",
                value: "",
            },
        ],
        number_of_child: "",
        occupation: "",
        // todo Studies now is an array, need to query at initialization, like notes
        // ongoing_studies: "",
        person_to_contact_for_appointment: "",
        pharmacy_address: "",
        pharmacy_fax_number: "",
        pharmacy_name: "",
        pharmacy_phone_number: "",
        phones: [],
        postal_code: "",
        potential: "",
        potential_date: "",
        preferred_communication_method: "",
        preferred_language: "",
        preferred_name: "",
        primary_clinic: "",
        primary_contact_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_relationship: "",
        primary_contact_phone_numbers: [],
        province: "",
        referred_by: "",
        reminder_date: "",
        secondary_contact_email: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relationship: "",
        secondary_contact_phone_numbers: [],
        sex: "",
        status: "",
        street_address: "",
        study_code: "",
        suppression_date: "",
        treatments: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                start_date: "",
                end_date: "",
                treatment: "",
                inactive: false,
                inactive_reason: "",
                indication: "",
                course_completed: false,
                patient: "00000000-0000-0000-0000-000000000000",
                patient_medication: {
                    uuid: "00000000-0000-0000-0000-000000000000",
                    administration_route: "",
                    dosage: "",
                    dosage_unit: "",
                    dose_frequency: "",
                    form: "",
                    medication: {
                        uuid: "00000000-0000-0000-0000-000000000000",
                        brand: "",
                        din: "",
                        forms: [""],
                        name_en: "",
                        name_fr: "",
                    },
                },
            },
        ],
        vitals: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                creatinine: "",
                date: "",
                diastolic_pressure: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                glucose_mmol_per_l: "",
                heart_rate: "",
                oxygen_level: "",
                respiration_rate: "",
                systolic_pressure: "",
                temperature_c: "",
                waist_circumference_cm: "",
                weight_kg: "",
                height_cm: "",
                bmi: "",
            },
        ],
        years_of_education: "",
    });
};
export const handle_appointments = () => {
    localStorage.voidAppointment = JSON.stringify({
        startDate: "",
        endDate: "",
        title: "Void Appointment",
        id: "00000000-0000-0000-0000-000000000000",
        medesync_id: "123123123",
        creation_date: "",
        cancellation_datetime: "",
        cancellation_note: "",
        cancellation_type: "",
        study_partner_required_duration_min: 0,
        appointment_type: "",
        appointment_status: "to_confirm",
        patient: JSON.parse(localStorage.voidPatient),
        patient_study_profile: "",
        clinic: "",
        appointment_creator: JSON.parse(localStorage.voidEmployee),
        room: "",
        employees_uuids: ["00000000-0000-0000-0000-000000000000"],
        employees: [JSON.parse(localStorage.voidEmployee)],
        notes: [],
        showed_up: false,
        chain: "",
        study: "",
        visit: "",
    });
};
export const handle_rooms = (rooms) => {
    localStorage.rooms = JSON.stringify(rooms);
    localStorage.roomsOptions = JSON.stringify(
        rooms.map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.roomsUUIDs = JSON.stringify(rooms.map((room) => room.uuid));
    localStorage.ottawaRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "ottawa")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.gatineauRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "gatineau")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.montrealRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "montreal")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
};
export const handle_appointment_assignable_employees = (
    appointment_assignable_employees,
    appointment_assignable_employees_all
) => {
    localStorage.appointment_assignable_employees = JSON.stringify(
        appointment_assignable_employees
    );
    localStorage.appointment_assignable_employees_all = JSON.stringify(
        appointment_assignable_employees_all
    );
};
export const medicationOrTherapyTemplate = {
    isYes: "notDecidedYet",
    startDate: {
        year: "",
        month: "",
        day: "",
    },
    isOnGoing: "",
    treatment: "",
    indication: "",
    inactive: "",
    inactive_reason: "",
    course_completed: "",
    endDate: {
        year: "",
        month: "",
        day: "",
    },
    medicationContext: {
        administration_route: "",
        dosage: "",
        dosage_unit: "",
        dose_frequency: "",
        form: "",
        medication: {
            uuid: "00000000-0000-0000-0000-000000000000",
            brand: "",
            din: "",
            forms: [""],
            name_en: "",
            name_fr: "",
        },
    },
};
export const handle_phone_pre_evaluation = () => {
    localStorage.voidPhonePreEvaluation = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        creation_date: "",
        patient: JSON.parse(localStorage.voidPatient),
        employee: JSON.parse(localStorage.voidEmployee),
        type: "phone-pre-evaluation",
        form: {
            version: "PPE.0.2.20241104",
            versionDescription: `Enable user to add multiple treatment records for one cancer record. Reference: https://github.com/Recherches-Neuro-Hippocampe/omc-data-frontend/issues/1786`,
            isStillInterested: "notDecidedYet",
            notInterestedReason: "",
            notInterestedDate: "",
            isHaveSpareTime: "notDecidedYet",
            isRescheduleSucceed: "notDecidedYet",
            rescheduleToThisDate: "",
            isIntact: "yes",
            consentToCollectData: "notDecidedYet",
            reasonOfTheConsultation: {
                whatIsTheReasonOfTheConsultation: "",
                areYourDTDActivitiesAffectedByYourMemoryChanges: "",
                dateOfOnset: {
                    year: "",
                    month: "",
                    day: "",
                },
            },
            haveYouEverBeenDiagnosedWithCancer: "notDecidedYet",
            cancerRecords: [],
            haveYouEverSufferedFromAStroke: "notDecidedYet",
            strokeRecords: [],
            doYouSufferFromAnyUnstableMedicalCondition: "notDecidedYet",
            unstableMedicalConditionRecords: [],
            doYouHaveACardiacPaceMaker: "notDecidedYet",
            yesHaveACardiacPaceMakerAndSoOn: [],
            haveYouEverBeenDiagnosedWithANeurodegenerativeDisease:
                "notDecidedYet",
            diagnosedNeurodegenerativeDiseases: [],
            doYouSufferFromEpilepsy: "notDecidedYet",
            epilepsyRecords: [],
            doYouHaveAnyUpcomingSurgeriesPlanned: "notDecidedYet",
            upcomingSurgeriesRecords: [],
            mandatoryTutorshipOrPowerOfAttorney: {
                isYes: "notDecidedYet",
                details: "",
            },
            wouldYouBeInterestedInParticipatingInClinicalResearch:
                "notDecidedYet",
            consent2AccessOrRequestMedicalHistory: {
                isYes: "notDecidedYet",
                pharmacyName: "",
                pharmacyAddress: "",
                familyPhysician: "",
                familyPhysicianAddress: "",
                OHIPNumber: "",
                RAMQNumber: "",
                other: "",
            },
            doYouHaveIntakePartnerInfo: "notDecidedYet",
            intakePartnerInfo: {
                firstName: "",
                lastName: "",
                relationshipToPatient: "",
                phone: [],
                email: "",
            },
            doYouHaveAdditionalInformation: "notDecidedYet",
            additionalInformation: [],
            areYouComfortableUsingCertainTechnologicalTools: {
                areYouComfortableUsingCertainTechnologicalTools:
                    "notDecidedYet",
                couldSomeoneAroundYouAssistYou: "notDecidedYet",
                yesSomeoneAroundYouAssistYou: "",
            },
            howWouldYouPreferToHaveYourInitialAssessment: "",
            scheduleAnIntake: "notDecidedYet",
        },
    });
};
export const handle_initial_evaluation = () => {
    localStorage.voidInitialEvaluation = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        creation_date: "",
        patient: JSON.parse(localStorage.voidPatient),
        employee: JSON.parse(localStorage.voidEmployee),
        type: "initial-evaluation",
        form: {
            version: "IE.0.10.20250124",
            versionDescription: `Add situation.haveYouBeenHospitalizedInTheLastYear.howLongDidYouStayTimeUnit`,
            isIntact: "yes",
            howIsTheVisitConducted: "",
            informationAndConsentForms: {
                initialEvaluationInformationAndConsentForm: "",
                consent2DataTransfer: "",
                caregiverConsent: "",
                ApoEConsent: "",
            },
            demographicInformation: {
                yearsOfEducation: "",
                highestGradeOfEducation: "",
                lastOccupation: "",
                currentEmploymentStatus: "",
                otherEmploymentStatus: "",
                dateOfRetirement: {
                    year: "",
                    month: "",
                    day: "",
                },
                numberOfChildren: "",
                maritalStatus: "",
                otherMaritalStatus: "",
                currentLivingSituation: [],
                otherCurrentLivingSituation: "",
                housingSituation: "",
                otherHousingSituation: "",
                ethnicity: "",
                race: "",
                otherRace: "",
                doYouConsiderYourself2BeASnowbird: "",
                doYouHavePrivateHealthInsurance: "",
            },
            situation: {
                haveYouBeenHospitalizedInTheLastYear: {
                    isYes: "notDecidedYet",
                    whenWereYouAdmitted: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    whichHospitalWereYouIn: "",
                    howLongDidYouStay: "",
                    howLongDidYouStayTimeUnit: "",
                    additionalInformation: "",
                },
                haveYouEverParticipatedInAClinicalTrial: {
                    isYes: "notDecidedYet",
                    records: [],
                },
            },
            familyHistoryOfAlzheimersDisease: {
                anyOfYourBiologicalRelativesDiagnosedWithADDementiaND:
                    "notDecidedYet",
                biologicalRelativesDiagnosedWithADDementiaNDRecords: [],
                haveYouEverCompletedGeneticTesting4AD: {
                    isYes: "notDecidedYet",
                    wouldYouLike2DiscloseTheResults: "notDecidedYet",
                    whatTest: "",
                    result: "",
                },
            },
            lifeHabits: {
                isVisitInPerson: "notDecidedYet",
                measure: {
                    height: "",
                    weight: "",
                    bmi: "",
                },
                sleep: {
                    hoursOfSleep: "",
                    isDiagnosedSleepingDisorder: "notDecidedYet",
                    diagnosedSleepingDisorder: "",
                    dateOfOnset: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    takingMedication2HelpSleep: [
                        { medicationOrTherapy: medicationOrTherapyTemplate },
                    ],
                },
                hoursOfPhysicalActivity: "",
                hoursOfSocialization: "",
                describeYourDiet: "",
                alcohol: {
                    isAlcohol: "notDecidedYet",
                    endDateAsAFormerConsumer: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    currentConsumer: {
                        beers: {
                            amount: "",
                            frequency: "",
                        },
                        wine: {
                            amount: "",
                            frequency: "",
                        },
                        spirits: {
                            amount: "",
                            frequency: "",
                        },
                        total: "",
                        isASubstanceAbuse: "notDecidedYet",
                    },
                },
                tobaccoOrNicotine: {
                    isTobaccoOrNicotine: "notDecidedYet",
                    endDateAsAFormerConsumer: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    currentConsumer: [],
                },
                recreationalDrugUsage: {
                    isRecreationalDrugUsage: "notDecidedYet",
                    records: [],
                    isASubstanceAbuse: "notDecidedYet",
                },
                DAST: [],
            },
            isMHAllergiesIntolerance: "notDecidedYet",
            medicalHistoryAllergiesIntolerance: [],
            isMHNeurological: "notDecidedYet",
            medicalHistoryNeurological: [],
            isMHEENT: "notDecidedYet",
            medicalHistoryEENT: [],
            isMHCardiovascular: "notDecidedYet",
            medicalHistoryCardiovascular: [],
            isMHRespiratory: "notDecidedYet",
            medicalHistoryRespiratory: [],
            isMHPsychiatric: "notDecidedYet",
            medicalHistoryPsychiatric: [],
            isMHGastrointestinal: "notDecidedYet",
            medicalHistoryGastrointestinal: [],
            isMHEndocrineMetabolic: "notDecidedYet",
            medicalHistoryEndocrineMetabolic: [],
            isMHGenitourinary: "notDecidedYet",
            medicalHistoryGenitourinary: [],
            isMHOther: "notDecidedYet",
            medicalHistoryOther: [],
            isMedicationAndHealthSupplements: "notDecidedYet",
            medicationAndHealthSupplements: [],
            cognitionMoodAndBehavior: {
                cognition: {
                    questions: [],
                    isYesForThisItem: [],
                },
                mood: [],
                behavior: [],
            },
            MMSEMoCAWechslerMemoryScale: {
                MMSE: {
                    administeredInWhichWay: "",
                    administeredByWhichEmployee: "",
                    date: "",
                    clinic: "",
                    score: "",
                    recall_score: "",
                },
                MoCA: {
                    administeredInWhichWay: "",
                    administeredByWhichEmployee: "",
                    date: "",
                    clinic: "",
                    score_numerator: "",
                    score_denominator: "",
                    recall_score_numerator: "",
                    recall_score_denominator: "",
                },
                WechslerMemoryScale: {
                    administeredInWhichWay: "",
                    administeredByWhichEmployee: "",
                    date: "",
                    clinic: "",
                    storyA_first_recall_score: "",
                    storyA_second_recall_score: "",
                    storyB_first_recall_score: "",
                    storyB_second_recall_score: "",
                    storyC_first_recall_score: "",
                    storyC_second_recall_score: "",
                },
                IQCODE: {
                    administeredInWhichWay: "",
                    administeredByWhichEmployee: "",
                    date: "",
                    clinic: "",
                    score: "",
                },
                AD8: {
                    administeredInWhichWay: "",
                    administeredByWhichEmployee: "",
                    date: "",
                    clinic: "",
                    score: "",
                },
            },
        },
    });
};
export const handle_caregiver_interview = () => {
    localStorage.voidCaregiverInterview = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        creation_date: "",
        patient: JSON.parse(localStorage.voidPatient),
        employee: JSON.parse(localStorage.voidEmployee),
        type: "caregiver-interview",
        form: {
            version: "CGI.0.1.20241219",
            versionDescription: `Add more questions, now is 26 questions.`,
            isConsentFormHasBeenSigned: "notDecidedYet",
            CGI_Q01: "",
            CGI_Q02: "",
            CGI_Q03: "",
            CGI_Q04: "",
            CGI_Q05: "",
            CGI_Q06: "",
            CGI_Q07: "",
            CGI_Q08: "",
            CGI_Q09: "",
            CGI_Q10: "",
            CGI_Q11: "",
            CGI_Q12: "",
            CGI_Q13: "",
            CGI_Q14: "",
            CGI_Q15: "",
            CGI_Q16: "",
            CGI_Q17: "",
            CGI_Q18: "",
            CGI_Q19: "",
            CGI_Q20: "",
            CGI_Q21: "",
            CGI_Q22: "",
            CGI_Q23: "",
            CGI_Q24: "",
            CGI_Q25: "",
            CGI_Q26: "",
        },
    });
};
export const handle_provinces = (provinces) => {
    localStorage.provinces = JSON.stringify(provinces);
    localStorage.provincesSimplifiedArray = JSON.stringify(
        provinces.map((province) => province.choice)
    );
    localStorage.provincesOptions = JSON.stringify(
        provinces.map((province) => [province.choice, province.representation])
    );
};
export const handle_cities = (cities) => {
    localStorage.cities = JSON.stringify(cities);
    let citiesSimplified = {};
    for (const province in cities) {
        citiesSimplified[province] = cities[province].map(
            (city) => city.choice
        );
    }
    localStorage.citiesSimplifiedArray = JSON.stringify(citiesSimplified);
    let citiesOptions = {};
    for (const province in cities) {
        citiesOptions[province] = cities[province].map((city) => [
            city.choice,
            city.representation,
        ]);
    }
    localStorage.citiesOptions = JSON.stringify(citiesOptions);
};
export const handlePatientStatusChoices = (choices) => {
    localStorage.patientStatusChoices = JSON.stringify(choices);
    localStorage.patientStatusChoicesSimplifiedArray = JSON.stringify(
        choices.map((choice) => choice.choice)
    );
    localStorage.patientStatusChoicesOptions = JSON.stringify(
        choices.map((choice) => [choice.choice, choice.representation])
    );
};
export const handleRelationshipToPatientChoices = (choices) => {
    localStorage.relationshipToPatientChoices = JSON.stringify(choices);
    localStorage.relationshipToPatientChoicesSimplifiedArray = JSON.stringify(
        choices.map((choice) => choice.choice)
    );
    localStorage.relationshipToPatientChoicesOptions = JSON.stringify(
        choices.map((choice) => [choice.choice, choice.representation])
    );
};
export const handleStudies = (studies) => {
    localStorage.studies = JSON.stringify(studies);
};
export default handleInitialization;
